import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaUserPlus, FaUsers } from "react-icons/fa";

import { friendsLoad } from "redux/actions/friends/friendsLoad";
import { friendAdd } from "redux/actions/friends/friendAdd";
import { friendRemove } from "redux/actions/friends/friendRemove";
import { friendAccept } from "redux/actions/friends/friendAccept";

import ButtonAdd from "../../components/buttons/ButtonAdd";
import FriendItem from "../../components/FriendItem";
import ModalAddFriend from "components/ModalAddFriend";
import ModalConfirm from "components/ModalConfirm";
import EmptyState from "components/EmptyState";
import Header from "components/Header";


import { snackbarShowMessage } from "redux/slices/snackbarSlice";

import "./style.scss";

const FriendList = () => {

  const dispatch = useDispatch();
  const { friends } = useSelector(state => state.friends);
  const [addFriend, setAddFriend] = useState(false);
  const [removeFriend, setRemoveFriend] = useState(false);

  const keys = Object.keys(friends);

  const onAddFriend = (formData) => {
    dispatch(friendAdd(formData.email));
    setAddFriend(false);
  };

  const onRemoveFriend = () => {
    dispatch(friendRemove(removeFriend));
    setRemoveFriend(false);
  };

  const onAcceptFriend = (friendId) => {
    dispatch(friendAccept(friendId));
    dispatch(snackbarShowMessage(`You and ${friends[friendId].username}  are now friends!`));
  };


  useEffect(() => {
    dispatch(friendsLoad());
  }, [dispatch]);


  return (
    <div className="friends-list">
      <Header title="Friend list" />
      <div className="scroll-list">
        {keys.map(key => {
          return (
            <FriendItem
              friend={friends[key]}
              onRemove={(id) => setRemoveFriend(id)}
              onAccept={(id) => onAcceptFriend(id)}
              key={key}
            />
          );
        })}
      </div>
      {keys.length === 0 &&
        <EmptyState
          icon={<FaUsers size="64" />}
          text="Add friends to check out their recipes"
        />
      }
      <ButtonAdd
        onClick={() => setAddFriend(true)}
        buttonIcon={<FaUserPlus size="26" />}
      />
      {addFriend &&
        <ModalAddFriend
          onConfirm={onAddFriend}
          onCancel={() => setAddFriend(false)}
        />
      }
      {removeFriend &&
        <ModalConfirm
          title="Remove friend"
          message="Are you sure you want to remove this friend?"
          onConfirm={onRemoveFriend}
          onCancel={() => setRemoveFriend(false)}
        />
      }
    </div>
  );
};

export default FriendList;