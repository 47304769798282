/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useState } from "react";
import { FaCamera, FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { FILES_URL } from "constants";
import ImageConvert from "helpers/ImageConvert";
import { userProfileImageUpdate } from "redux/actions/user/userProfileImageUpdate";


import "./style.scss";

const ProfileImageEditable = ({ image }) => {

  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState(image ? `${FILES_URL}${image}` : null);
  const fileRef = useRef(null);

  const selectFile = () => {
    fileRef.current.click();
  };

  const onFileSelected = async ({ target }) => {
    const files = target.files;
    const imageData = await ImageConvert.toBase64(files[0], 256 * 256);
    URL.createObjectURL(files[0])
    setProfileImage(imageData);
    dispatch(userProfileImageUpdate(imageData));
  };


  return (
    <div className="profile-image-editable">
      <input
        className="hidden-file"
        type="file"
        onChange={onFileSelected}
        ref={fileRef}
      />
      {profileImage ? <img
        src={`${profileImage}`}
        className="profile-image"
        alt="profile picture"
      /> : <FaUser className="profile-placeholder" size={56} />
      }
      <div onClick={selectFile} className="image-change">
        <FaCamera />
      </div>
    </div>
  );
}

export default ProfileImageEditable;