import React from "react";
import showdown from "showdown";

import "./style.scss";

const converter = new showdown.Converter({
    openLinksInNewWindow: true
});

const MarkdownView = ({ text }) => {
    return (
        <div
            className="markdown-view"
            dangerouslySetInnerHTML={{ __html: converter.makeHtml(text) }}
        />
    );

};


export default MarkdownView;
