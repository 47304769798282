import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { cardsLoaded, cardsLoading } from "redux/slices/cardsSlice";

export const cardsLoad = () =>
  async (dispatch, getState) => {
    
    dispatch(cardsLoading());

    const { filters } = getState().cards;

    const { token } = getState().user;
    const data = await doRequest(`${API_URL}/recipe-cards`, "POST", {
      people: filters.people,
      minutes: filters.minutes,
      tags: filters.tags
    }, token);

    const mappedCards = {};
    data.forEach(recipe => {
      mappedCards[recipe.id] = {
        ...recipe,
        id: recipe.id
      };
    });

    dispatch(cardsLoaded(mappedCards));
  };