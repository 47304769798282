import DotMenu from "components/DotMenu";
import { FILES_URL } from "constants";
import React from "react";
import { FaTrash, FaUser } from "react-icons/fa";

import "./style.scss";

const FriendItem = ({ friend, onRemove, onAccept }) => {

  const statusMessage = {
    PENDING: "Pending ",
    ACCEPTED: "Friends",
    RECEIVED: "Request"
  };

  const { username, image, status } = friend;

  return (
    <div className="friend-item" >
      <div className="profile-image">
        {image ? <img alt="profile" src={`${FILES_URL}${image}`} /> : <FaUser />}
      </div>
      <div className="content">
        <span className="username">
          {username}
        </span>

        <div className="container-end">

          <span className="status">
            {statusMessage[status]}
          </span>
          <span className="actions">

            {status === "RECEIVED" ?
              <DotMenu
                menuItems={[
                  { label: "Accept", action: ()=> onAccept(friend.id)},
                  { label: "Decline", action: ()=> onRemove(friend.id)},
                ]} />
              :
              <DotMenu
                menuItems={[
                  { label: "Show", action: ()=>{}},
                  { label: "Remove", action: ()=> onRemove(friend.id)},
                ]} />
            }
          </span>
        </div>
      </div>
    </div>
  );
}

export default FriendItem;