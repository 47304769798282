import React from 'react';
import classNames from "classnames";

import "./style.scss"

export default function Button({
  label,
  inline,
  onClick,
  secondary,
  anchored,
  white
}) {
  const classes = classNames(
    "button",
    inline && "inline",
    secondary && "secondary",
    white && "white",
    anchored && "anchored"
  );

  return (
    <div
      className={classes}
      onClick={onClick}
    >
      {label}
    </div>
  )
}