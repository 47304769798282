import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  error: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    snackbarShowMessage: (state, {payload }) => {
      state.error =false;
      state.open = true;
      state.message = payload;
    },
    snackbarShowError: (state, { payload }) => {
      state.error  = true;
      state.open = true;
      state.message = payload;
    },
    snackbarHide: (state) => {
      state.error = false;
      state.open = false;
      state.message ="";
    }
  }
});

export const {
  snackbarShowError,
  snackbarShowMessage,
  snackbarHide
} = snackbarSlice.actions;


export default snackbarSlice.reducer;