import EXIF from "exif-js";
export default class ImageConvert {

  static getRequiredRotation(img) {
    return new Promise((resolve) => {
      try {
        EXIF.getData(img, function () {
          const orientation = EXIF.getTag(this, "Orientation");
          resolve(orientation);
        });
      } catch (error) {
        resolve(0);
      }
    });
  }
  static toBase64(file, mp) {
    return new Promise((resolve, reject) => {
      try {
        let img = new Image();
        let reader = new FileReader();
        let canvas = document.createElement("canvas");
        // Read in the image file as a data URL.
        reader.readAsDataURL(file);
        reader.onload = (evt) => {
          if (evt.target.readyState == FileReader.DONE) {
            img.onload = () => {
              ImageConvert.getRequiredRotation(img).then((orientation) => {
                let { width, height } = img;
                const currentMP = width * height;
                const resizeFactor = Math.sqrt(mp) / Math.sqrt(currentMP);

                if (resizeFactor < 1) {
                  width *= resizeFactor;
                  height *= resizeFactor;
                }
                canvas.width = width;
                canvas.height = height;

                let context = canvas.getContext("2d");
                context.drawImage(img, 0, 0, width, height);
                let data = canvas.toDataURL("image/jpeg");
                
                resolve(data);
              });

            }
            img.src = evt.target.result;
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}