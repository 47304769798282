import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "components/buttons/Button";
import InputText from "components/input/InputText";
import Loader from "components/Loader";

import { userRegister } from "redux/actions/user/userRegister";
import { snackbarShowError } from "redux/slices/snackbarSlice";
import { userLoaded } from "redux/slices/userSlice";

import "./style.scss";

const Register = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, errors, watch } = useForm();
  const { loading } = useSelector(state => state.user);
  
  const submitLogin = async ({ username, email, password, passwordRepeat }) => {
    try {
      await dispatch(userRegister(username, email, password))
      history.push("/app/swipe");
    } catch (error) {
      dispatch(userLoaded());
      dispatch(snackbarShowError("Error trying to register."))
    }
  }


  return (
    <div className="screen-register">
      {!loading ?
        <>
          <div className="container-logo">
            <div className="logo">
              <img
                className="logo-image"
                alt="Food swipe logo"
                src={require("assets/img/logo.svg").default}
              />
              <h1 className="logo-header">Dinner Deck</h1>
            </div>
          </div>
          <div className="container-form">
            <h2>Register</h2>
            <div className="spacer" />
            <InputText
              label="Username"
              name="username"
              control={control}
              error={errors.username}
              rules={{ required: true }}
            />
            <InputText
              label="Email address"
              name="email"
              control={control}
              error={errors.email}
              rules={{ required: true }}
              type="email"
            />
            <InputText
              label="Password"
              name="password"
              type="password"
              control={control}
              error={errors.password}
              rules={{ required: true }}
            />
            <InputText
              label="Password repeat"
              name="passwordRepeat"
              type="password"
              control={control}
              error={errors.passwordRepeat}
              rules={{
                required: true,
                validate: (val) => {
                  if (watch('password') !== val) {
                    return "Your passwords do no match";
                  }
                },
              }}
            />
            <div className="spacer"/>
            <Button label="Register" onClick={handleSubmit(submitLogin)} />
            <div className="spacer" />
            <Button
              label="Back to log in"
              secondary
              onClick={() => history.push("/app/login")}
            />
          </div>
        </>
        : <Loader />
      }
    </div>
  );
}

export default Register;