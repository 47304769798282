import React from 'react';
import classNames from "classnames";

import "./style.scss"

export default function ButtonAnchor({
  label,
  inline,
  fileName,
  onClick,
  onWhite,
  spacedRight
}) {
  const classes = classNames(
    "button-anchor",
    inline ? "inline" : "",
    onWhite ? "on-white" : "",
    spacedRight ? "spaced-right" : ""
  );

  return (
    <div
      download={fileName}
      className={classes}
      onClick={onClick}
    >
      {label}
    </div>
  )
}