import { IconButton } from "@material-ui/core";
import React from "react";


const ButtonIconEffect = ({ className, icon, onClick, color }) => {
  return (
    <IconButton
      className={`button-icon ${className}`}
      color={color}
      component="label"
      onClick={onClick}
    >{icon}
    </IconButton>
  );
}

export default ButtonIconEffect;
