import { cardSwiped } from "redux/slices/cardsSlice";
import { cardsLoad } from "./cardsLoad";

export const cardSwipe = (id) =>
  async (dispatch, getState) => {
    await dispatch(cardSwiped(id));
    
    const { cards  } = getState().cards;

    const cardIds = Object.keys(cards);
    const allSwiped = !cardIds.some(cardId=> !cards[cardId].swiped);

    if (allSwiped) {
      console.log("should reload");
      dispatch(cardsLoad());
    }
  };