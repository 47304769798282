import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBook, FaHeart, FaUser, FaUsers, FaUtensilSpoon } from "react-icons/fa";

import Tab from "./components/Tab";
import Swipe from "./pages/Swipe";
import Login from "pages/Login";
import Profile from "pages/Profile";
import FriendList from "pages/FriendList";
import RecipeList from "./pages/RecipeList";
import RecipeOpen from "./pages/RecipeOpen";
import Register from "pages/Register";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import { snackbarHide } from "redux/slices/snackbarSlice";
import Favorites from "pages/Favorites";
import RecipeForm from "pages/RecipeForm";
import { userLoad } from "redux/actions/user/userLoad";


const AppRoutes = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector(state => state.user);
  const {
    open: snackbarOpen,
    message: snackbarMessage
  } = useSelector(state => state.snackbar);


  useEffect(() => {
    if (isLoggedIn && (
      location.pathname === "/app/login" ||
      location.pathname === "/app/register"
    )
    ) {
      history.push("/app/swipe");
    }

  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(userLoad());
      // dispatch(tagsLoad());
    }
  }, [isLoggedIn])

  const renderLoggedIn = () => {
    return (
      <>
        <div className="container-content">
          <Route exact path="/app/" component={Swipe} />
          <Route path="/app/recipes" component={RecipeList} />
          <Route exact path="/app/recipes/show/:recipeId" component={RecipeOpen} />
          <Route exact path="/app/recipes/card-form/:step" component={RecipeForm} />
          <Route exact path="/app/recipes/card-form/:step/:recipeId" component={RecipeForm} />

          <Route path="/app/swipe" component={Swipe} />
          <Route exact path="/app/swipe/:recipeId" component={RecipeOpen} />
          <Route exact path="/app/profile" component={Profile} />
          <Route exact path="/app/friends" component={FriendList} />
          <Route exact path="/app/favorites" component={Favorites} />
        </div>
        <div className="container-tabs">
          <div className="tab-group">
            <Tab
              label="Recipes"
              path="/app/recipes"
              icon="recipes"
            />
            <Tab
              label="Favorites"
              path="/app/favorites"
              icon="heart"
            />
          </div>
          <Tab
            label="Swipe"
            path="/app/swipe"
            highlighted
            icon="swipe"
          />
          <div className="tab-group">

            <Tab
              label="Profile"
              path="/app/friends"
              icon="friends"
            />
            <Tab
              label="Profile"
              path="/app/profile"
              icon="profile-edit"
            />
          </div>
        </div>
      </>
    );
  };

  const renderLoggedOut = () => {
    return (
      <>
        <Route exact path="/app" component={Login} />
        <Route exact path="/app/login" component={Login} />
        <Route exact path="/app/register" component={Register} />
      </>
    )
  }


  return (
    <div className="App">
      {isLoggedIn ? renderLoggedIn() : renderLoggedOut()}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        message={snackbarMessage}
        onClose={() => dispatch(snackbarHide())}
      />
    </div>
  );
}

export default AppRoutes;