import axios from "axios";

export default async function doRequest(url, method = "get", data, token = undefined) {

  const headers = {
      Authorization: token && `Bearer ${token}`,
      "Content-Type": 'application/json',
  }

  const { data: resultData } = await axios({ 
    method,
    url, 
    data, 
    headers 
  });

  return resultData;

}