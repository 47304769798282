import { FILES_URL } from "constants";
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { useHistory } from "react-router";

import "./style.scss";

const RecipeItem = ({ recipe, onRemove }) => {

  const history = useHistory();



  return (
    <div
      className="recipe-item"
    onClick={() => history.push(`/app/recipes/card-form/1/${recipe.id}`)}
    >
      {recipe.images?.length > 0 &&
        <img
          alt={recipe.label}
          src={`${FILES_URL}${recipe.images[0].url}`}
          className="background-image"
        />
      }
      <div className="content">
        <span className="title">
          {recipe.label}
        </span>
        <span className="tags">
        </span>
      </div>
    </div>
  );
}

export default RecipeItem;