import React, { useState } from "react";
import { FaBars, FaHamburger } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./index.scss";

const HeaderPage = () => {


  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const renderLogo = () => {
    return (
      <Link to="/" className="header-logo">
        <img
          src={require('../../assets/img/logo.svg').default}
          className="app-logo"
          alt="logo"
        />
        <div className="title-and-slogan">
          <div className="title">Dinner Deck</div>
        </div>
      </Link>
    );
  }

  return (
    <header className="app-header">
      {renderLogo()}
      <div className="container-menu-desktop">
        <div>
          <Link className="menu-link" to="/">About</Link>
          <Link className="menu-link" to="/about">Recipes</Link>
          <Link className="menu-link" to="/blog">Blog</Link>
          <Link className="menu-link" to="/faq">FAQ</Link>
          <Link className="menu-link" to="/privacy">Download</Link>
        </div>
      </div>
      <FaBars
        className="menu-button-mobile"
        size={28}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      />

      {showMobileMenu &&
        <div
          onClick={() => setShowMobileMenu(false)}
          className="container-menu-mobile"
        >
          <Link className="menu-button-outlined" to="/">Transfer</Link>
          <Link className="menu-button-outlined" to="/about">About</Link>
          <Link className="menu-button-outlined" to="/blog">Blog</Link>
          <Link className="menu-button-outlined" to="/faq">FAQ</Link>
          <Link className="menu-button-outlined" to="/privacy">Privacy</Link>
          <Link className="menu-button" to="/download">Download</Link>
          <Link className="menu-button-outlined" to="/mobile">  Mobile Connect</Link>
        </div>
      }
    </header>

  );

}

export default HeaderPage;
