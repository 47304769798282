import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaClock, FaUsers } from "react-icons/fa";

import "./style.scss";

const CardPreview = ({ recipe, index }) => {

  const [cardClasses, setCardClasses] = useState(`swiper--card preview`)
  useEffect(()=> {
    setCardClasses(`swiper--card preview c${index}`);

  },[])

  return (
    <div className={cardClasses} >
      <span className="content">
          <img
            src={recipe.image}
            className="image"
            alt="The recipe"
          />

        <footer className="content-bottom">
          <h2>{recipe.label}</h2>
          <div className="spacer"/>
          <div className="cols">
            <div className="col stats">
              <FaClock />
              <span>{`${recipe.duration} minutes`}</span>
            </div>
            <div className="col stats">
              <FaUsers />
              <span>{`${recipe.people} people`}</span>
            </div>
          </div>
        </footer>
      </span>
    </div>

  );
}

export default CardPreview;