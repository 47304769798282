
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Header from "components/Header";
import { FaCheck } from "react-icons/fa";

import AddPhotos from "./AddPhotos";
import AddTitle from "./AddTitle";

import "./style.scss";
import DetailForm from "./DetailForm";


const RecipeForm = () => {

  const history = useHistory();
  const { recipeId, step } = useParams();

  const { recipes } = useSelector(state => state.recipes);
  const recipe = recipeId ? recipes[recipeId] : null;

  const [hiding, setHiding] = useState(false);


  const onBack = (fast = false) => {
    if (step > 1) {

    } else {
      setHiding(true);
      setTimeout(() => {
        history.push("/app/recipes");
      }, 400)
    }
  }

  const onNextStep = () => {
    debugger;
    const nextStep = parseInt(step) + 1;
    if(recipeId) {
    history.push(`/app/recipes/card-form/${nextStep}/${recipeId}`);
    } else {
      history.push(`/app/recipes/card-form/${nextStep}`);

    }
  }


  const renderStep = () => {
      switch (step) {
        case "1":
          return <AddTitle recipe={recipe} onSaved={onNextStep} />;
        case "2":
          return <AddPhotos recipe={recipe} onSaved={onNextStep} />;
        default:
          return null;
      }
  }

return (
  <div className={`recipe-card-form ${hiding ? "hiding" : ""}`}>
    <Header
      title={`${step}/4`}
      onBack={() => onBack()}
      onAction={onNextStep}
      actionIcon={<FaCheck size="16" />}
    />
    <div className="container-form">
      {renderStep()}
    </div>
  </div>
);
};

export default RecipeForm;