import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favorites: {},
  loading: false
};

export const favoritesSlice = createSlice({
  name: "friends",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    favoritesLoaded: (state, { payload }) => {
      state.favorites = payload;
    },
    favoriteAdded: (state, { payload: favorite }) => {
      state.favorites[favorite.id] = favorite;
    },
    favoriteRemoved: (state, { payload: id }) => {
      delete state.favorite[id];
    },
  }
});

export const { 
  favoritesLoaded, 
  favoriteAdded, 
  favoriteRemoved
} = favoritesSlice.actions;


export default favoritesSlice.reducer;