import React from "react";
import ReactDOM from "react-dom";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import store from "./redux/store";

import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f34748", //this overide blue color
      light: "#ddd", //overides light blue
      dark: "#333", //overides dark blue color
    },
  },
});

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}> 
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
