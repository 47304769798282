import ButtonIconEffect from "components/buttons/ButtonIconEffect";
import ProfileImage from "components/ProfileImage";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";

import "./style.scss";

const Header = ({ onAction, actionIcon, onBack, title }) => {

  return (
    <div className="header">
      {onBack &&
        <ButtonIconEffect
          icon={<FaArrowLeft color="white" size="16" />}
          className="back"
          onClick={onBack}
          color="white"
        /> 
      }
      <span className="title">{title}</span>
      {onAction &&
        <ButtonIconEffect
          icon={actionIcon}
          className="action"
          onClick={onAction}
        />
      }
    </div>
  );
}

export default Header;