import React, { useState } from "react";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-prebuilt-basic-styles";
import showdown from "showdown";

import "./style.scss";

const converter = new showdown.Converter();

const InputMarkdown = ({
    label,
    onChange,
    defaultValue,
    dataTestId,
}) => {

    return (
        <div
            data-testid={dataTestId}
            className="input-markdown">
            <CKEditor
                id={"ckeditor5"}
                config={{
                    placeholder: label,
                    toolbar: [
                        "bold",
                        "bulletedList", "numberedList"
                    ]
                }}
                data={converter.makeHtml(defaultValue)}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                    const markdown = converter.makeMarkdown(editor.getData());
                    onChange(markdown);
                }}
            />
        </div>
    );
};

InputMarkdown.propTypes = {
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
};

export default InputMarkdown;
