import React from "react";
import { TextField, FormHelperText } from "@material-ui/core";
import { Controller } from "react-hook-form";

import "./style.scss";

const InputText = ({
  label,
  defaultValue,
  rules,
  name,
  type,
  control,
  error
}) => {


  return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="container-input">
          <TextField
              label={label}
              fullWidth
              name={name}
              defaultValue={value}
              error={!!error}
              type={type || "text"}
              onChange={onChange}
              onBlur={onBlur}
            />
            {!!error &&
              <FormHelperText>
                This field is required
              </FormHelperText>
            }
          </div>
        )}
      />
  );
};


export default InputText;