import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { favoriteAdded } from "redux/slices/favoritesSlice";

export const favoriteAdd = (recipe) =>
  async (dispatch, getState) => {
    try {
      const { token } = getState().user;

      const { data: favorite } = await doRequest(`${API_URL}/favorites`, "POST", 
        JSON.stringify({
          data: {
            recipe: recipe.id
          }
        })
        , token);

      dispatch(favoriteAdded({ id: favorite.id, ...recipe }));

    } catch (error) {
      console.error(error);
    }

  };