import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { userLoaded } from "redux/slices/userSlice";

export const userLoad = () =>
  async (dispatch, getState) => {

    const { token } = getState().user;
    const profile  = await doRequest(`${API_URL}/profile`, "GET", null, token);
    dispatch(userLoaded({...profile, image: profile.profileImage.url}));

  };