import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import { MenuItem, Chip, OutlinedInput, FormControl, InputLabel } from "@material-ui/core";


import "./style.scss";
import { Controller } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null
};

export default function InputMultiSelect({
  items,
  defaultValue,
  name,
  label,
  rules,
  control
}) {

  const mappedLabels = {};
  items.forEach(({ value, label }) => {
    mappedLabels[value] = label;
  });

  const renderField = () => {
    return (

      <Select
        label={label}
        name={name}
        variant="outlined"
        multiple
        MenuProps={MenuProps}
        fullWidth
        input={
          <OutlinedInput
            labelWidth={label.length * 8}
          />
        }
        renderValue={(selected) => (
          <div className="container-chips">
            {selected.map((value) =>
              <Chip
                key={value}
                label={mappedLabels[value]}
              />
            )}
          </div>
        )}
      >
        {items.map((item, index) => (
          <MenuItem
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>

    );
  };

  return (
    <div className="container-multi-select">
      <FormControl
        variant="outlined"
        fullWidth
        name={name}
      >
        <InputLabel className="multi-label">{label}</InputLabel>
        <Controller
          as={renderField()}
          rules={rules}
          name={name}
          control={control}
          defaultValue={defaultValue}
        />
      </FormControl>
    </div>
  )

}

