import Button from "components/buttons/Button";
import InputText from "components/input/InputText";
import { useForm } from "react-hook-form";

import "./style.scss";

const ModalAddFriend = ({
  onConfirm,
  onCancel
}) => {

  const { control, handleSubmit, errors } = useForm();

  return (
    <div className="modal-background">
      <div className="popup">
        <h3>Add a friend</h3>
        <p>Enter the email address of your friend to see their recipes</p>
        <div className="spacer" />
        <InputText
          label="Email address"
          name="email"
          control={control}
          errors={errors.email}
          rules={{ required: true }}
        />
                <div className="spacer" />

        <div className="cols">
          <div className="col">
            <Button
              onClick={onCancel}
              secondary
              label="Cancel"
            />
          </div>
          <div className="col">
            <Button
              onClick={handleSubmit(onConfirm)}
              label="Add"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAddFriend;