
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FaBook, FaBookMedical } from "react-icons/fa";
import { recipeRemove } from "redux/actions/recipes/recipeRemove";
import { recipesLoad } from "redux/actions/recipes/recipesLoad";

import ButtonAdd from "../../components/buttons/ButtonAdd";
import RecipeItem from "../../components/RecipeItem";
import ModalConfirm from "components/ModalConfirm";
import Header from "components/Header";
import EmptyState from "components/EmptyState";

import "./style.scss";


const RecipeList = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  
  const { recipes } = useSelector(state => state.recipes);
  const [removeRecipe, setRemoveRecipe] = useState(false);

  const keys = Object.keys(recipes);

  useEffect(() => {
    dispatch(recipesLoad());
  }, [dispatch]);

  const onRemoveRecipe = () => {
    dispatch(recipeRemove(removeRecipe));
    setRemoveRecipe(false);
  };

  return (
    <>
      <Header title="Recipes" />
      <div className="scroll-list">
        <div className="recipe-list">
          {keys.map(key => {
            return (
              <RecipeItem
                recipe={recipes[key]}
                key={key}
                onRemove={(recipeId) => setRemoveRecipe(recipeId)}
              />
            );
          })}
        </div>
        {keys.length === 0 &&
          <EmptyState
            icon={<FaBook size="64" />}
            text="Start adding recipes with the button on the bottom right"
          />
        }
        <ButtonAdd
          buttonIcon={<FaBookMedical size="26" />}
          onClick={() => history.push("/app/recipes/card-form/1")}
        />
        {removeRecipe &&
          <ModalConfirm
            title="Removed recipe"
            message="Are you sure you want to remove this recipe?"
            onConfirm={onRemoveRecipe}
            onCancel={() => setRemoveRecipe(false)}
          />}
      </div>
    </>
  );
};

export default RecipeList;