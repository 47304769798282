import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  friends: {},
  loading: false
};

export const friendsSlice = createSlice({
  name: "friends",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    friendsLoaded: (state, { payload }) => {
      state.friends = payload;
    },
    friendAdded: (state, { payload: friend }) => {
      state.friends[friend.id] = friend;
    },
    friendRemoved: (state, { payload: id }) => {
      delete state.friends[id];
    },
    friendAccepted: (state, { payload: id }) => {
      state.friends[id].status = "ACCEPTED";
    },
  }

});

export const { 
  friendsLoaded, 
  friendAdded, 
  friendRemoved, 
  friendAccepted
} = friendsSlice.actions;


export const selectFriends = (state) => state.recipes.recipes;

//   // We can also write thunks by hand, which may contain both sync and async logic.
//   // Here's an example of conditionally dispatching actions based on current state.
//   export const incrementIfOdd = (amount) => (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default friendsSlice.reducer;