import InputSwitch from "components/input/InputSwitch";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLoggedOut } from "redux/slices/userSlice";
import { dispatch } from "redux/store";

import ProfileImageEditable from "components/ProfileImageEditable";
import ButtonAnchor from "components/buttons/ButtonAnchor";


import "./style.scss";

const Profile = () => {

    const history = useHistory();
    const { username, image } = useSelector(state => state.user.profile);
    const { control, handleSubmit, errors } = useForm();

    const logout = () => {
        localStorage.clear();
        dispatch(userLoggedOut());
        history.push("/app/login");
    };

    return (
        <div className="profile">

            {/* <InputText
                label="Username"
                name="username"
                control={control}
                error={errors.username}
                rules={{ required: true }}
            />
            <InputText
                label="Email address"
                name="email"
                control={control}
                error={errors.email}
                rules={{ required: true }}
                type="email"
            /> */}
            <ProfileImageEditable image={image} />
            <span className="username">{username}</span>
            <div className="divider" />
            <h3>Preferences</h3>
            <InputSwitch
                control={control}
                name="toggle"
                error={errors.toggle}
                label="Friends may see my recipes"
            />
            <InputSwitch
                control={control}
                name="toggle"
                error={errors.toggle}
                label="Make my recipes public"
            />
            <div className="divider" />
            <ButtonAnchor
                secondary
                label="Log out"
                onClick={logout}
            />
        </div>
    );
}

export default Profile;