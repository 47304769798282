import React from "react";
import { FormControlLabel, FormHelperText, Switch } from "@material-ui/core";
import { Controller } from "react-hook-form";

import "./style.scss";

const InputSwitch = ({
  label,
  defaultValue,
  rules,
  name,
  type,
  control,
  error
}) => {

  const renderField = () => {
    return (
      <div className="container-input">
        <FormControlLabel
          control={<Switch defaultChecked />}
          label={label}
          error={!!error}
        />
        {!!error &&
          <FormHelperText>
            This field is required
          </FormHelperText>
        }
      </div>
    );
  }

  return (
    <Controller
      as={renderField()}
      rules={rules}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};


export default InputSwitch;