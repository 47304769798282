import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist"
import thunk from "redux-thunk"

import recipesReducer from "../slices/recipesSlice";
import userReducer from "../slices/userSlice";
import cardsReducer from "../slices/cardsSlice";
import friendsReducer from "../slices/friendsSlice";
import snackbarReducer from "../slices/snackbarSlice";
import favoritesReducer from "../slices/favoritesSlice";

const reducers = combineReducers({
  recipes: recipesReducer,
  user: userReducer,
  cards: cardsReducer,
  friends: friendsReducer,
  snackbar: snackbarReducer,
  favorites: favoritesReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["recipes", "user", "cards", "favorites"]
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

const getState = store.getState;
const dispatch = store.dispatch;

export default store;

export { getState, dispatch };
