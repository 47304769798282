import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import Hammer from "hammerjs";
import { FaFilter, FaRandom } from "react-icons/fa";

import Card from "components/Card";

import { cardsLoad } from "redux/actions/cards/cardsLoad";
import { cardSwipe } from "redux/actions/cards/cardSwipe";

import Loader from "components/Loader";
import EmptyState from "components/EmptyState";
import CardsFilter from "components/CardsFilter";
import Header from "components/Header";

import "./style.scss";

const Swipe = () => {

  const { cards: recipes, loading } = useSelector(state => state.cards);

  const [showFilters, setShowFilters] = useState(false);
  const swiperContainer = useRef();
  const dispatch = useDispatch();


  useEffect(() => {

    const cardIds = Object.keys(recipes);
    const allSwiped = !cardIds.some(cardId=> !recipes[cardId].swiped);

    if (allSwiped) {
      dispatch(cardsLoad());
    }
  }, []);



  useEffect(() => {
    if (!loading) {
      var allCards = document.querySelectorAll('.swiper--card');

      swiperContainer.current.classList.add('loaded');

      allCards.forEach(function (el) {
        var hammertime = new Hammer(el);

        hammertime.on('pan', function (event) {
          el.classList.add('moving');
        });

        hammertime.on('pan', function (event) {
          if (event.deltaX === 0) return;
          if (event.center.x === 0 && event.center.y === 0) return;

          var xMulti = event.deltaX * 0.03;
          var yMulti = event.deltaY / 80;
          var rotate = xMulti * yMulti;

          el.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
        });

        hammertime.on('panend', function (event) {

          el.classList.remove('moving');

          var moveOutWidth = document.body.clientWidth;
          var remove = Math.abs(event.deltaX) > 100 || Math.abs(event.velocityX) > 0.5;

          console.log(" Math.abs(event.deltaX)", Math.abs(event.deltaX));
          event.target.classList.toggle('removed', remove);

          if (!remove) {
            el.style.transform = '';
          } else {
            var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
            var toX = event.deltaX > 0 ? endX : -endX;
            var endY = Math.abs(event.velocityY) * moveOutWidth;
            var toY = event.deltaY > 0 ? endY : -endY;
            var xMulti = event.deltaX * 0.03;
            var yMulti = event.deltaY / 80;
            var rotate = xMulti * yMulti;

            el.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)';

            setTimeout(() => {
              console.log("should remove", el.id);
              dispatch(cardSwipe(el.id));
            }, 100);

            setTimeout(() => {
              el.style.display = "none";
            }, 500);


          }
        });
      });
    }

  }, [loading])



  const keys = Object.keys(recipes).filter(key=>{
    return !recipes[key].swiped;
  });

  return (
    <>
      <Header
        title="Swipe"
        onAction={() => setShowFilters(true)}
        actionIcon={<FaFilter size="16" />}
      />
      <div className="swiper" ref={swiperContainer}>
        {keys.map(key =>
          <Card key={key} recipe={recipes[key]} />
        )}
        {loading && <Loader />}
        {!loading && keys.length === 0 &&
          <EmptyState
            icon={<FaRandom size="64" />}
            text="No recipes found. Start by adding your own recipes!"
          />
        }
      </div>
      {showFilters &&
        <CardsFilter onClose={() => setShowFilters(false)} />
      }
    </>
  );
}

export default Swipe;