import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { friendAdded } from "redux/slices/friendsSlice";

// Here's an example of conditionally dispatching actions based on current state.
export const friendAdd = (email) =>
  async (dispatch, getState) => {
    try {
      const { token } = getState().user;

      const friend = await doRequest(`${API_URL}/friends`, "POST", {
        email
      }, token);

      debugger;

      dispatch(friendAdded(friend));

    } catch (error) {
      console.error(error);
    }

  };