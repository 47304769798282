import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: {},
  token: null,
  isLoggedIn: false,
  loading: false
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    userLoading: (state) => {
      state.loading = true;
    },
    userLoaded: (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.profile = payload;
      }
    },
    userLoggedOut: (state, { payload }) => {
      state.loading = false;
      state.token = null;
      state.profile = {};
      state.isLoggedIn = false;
    },
    userLoggedIn: (state, { payload }) => {
      const { user, jwt } = payload;
      state.loading = false;
      state.profile = user;
      state.token = jwt;
      state.isLoggedIn = true;
    }
  }

});

export const {
  userLoading,
  userLoaded,
  userLoggedIn,
  userLoggedOut
} = userSlice.actions;


export default userSlice.reducer;