import axios from "axios";
import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { recipeAdded } from "redux/slices/recipesSlice";

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

// Here's an example of conditionally dispatching actions based on current state.
export const recipeAdd = (recipe) =>
  async (dispatch, getState) => {
    const { token } = getState().user;
    const {
      label,
      people,
      duration,
      ingredients,
      description,
      tags,
      image
    } = recipe;

    let formData = new FormData();
    const imageFile = dataURLtoFile(image);
    
    formData.append("files", imageFile);

    const { data: images } = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      }
    })

    const imageIds = images.map(image => {
      return image.id;
    });

    const { data: recipeResult } = await doRequest(
      `${API_URL}/recipes`,
      "POST",
      JSON.stringify({
        data: {
          label,
          people,
          duration,
          description,
          ingredients,
          tags,
          images: imageIds
        }
      }), token);

    await dispatch(recipeAdded({
      ...recipeResult,
      images
    }));
  };