// We can also write thunks by hand, which may contain both sync and async logic.

import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { userLoading, userLoggedIn } from "redux/slices/userSlice";

export const userLogin = (identifier, password) =>
  async (dispatch) => {
    dispatch(userLoading());
    const { jwt, user } = await doRequest(`${API_URL}/auth/local`, "POST", {
      identifier,
      password
    });
    dispatch(userLoggedIn({ jwt, user }));
  };