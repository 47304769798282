import { useDispatch } from "react-redux";
import { useRef, useState } from "react";

import { FILES_URL } from "constants";

import ImageConvert from "helpers/ImageConvert";
import { recipeAdd } from "redux/actions/recipes/recipeAdd";
import { recipeUpdate } from "redux/actions/recipes/recipeUpdate";
import PhotoItem from "components/PhotoItem";
import Button from "components/buttons/Button";


const AddPhotos = ({ recipe, onSaved }) => {

  const dispatch = useDispatch();
  const fileRef = useRef(null);

  const [recipeImage, setRecipeImage] = useState(recipe?.images ? `${FILES_URL}${recipe.images[0].url}` : "");


  const selectFile = () => {
    fileRef.current.click();
  };

  const onFileSelected = async ({ target }) => {
    const files = target.files;
    debugger;
    const imageData = await ImageConvert.toBase64(files[0], 512 * 512);
    URL.createObjectURL(files[0])
    setRecipeImage(imageData);
  };

  const onSaveRecipe = async () => {
    let recipeId = 0;
    if (!recipe) {
      recipeId = await dispatch(recipeAdd(
        {
          images: recipeImage
        }
      ));
    } else {
      recipeId = recipe.id;
      await dispatch(recipeUpdate(
        recipeId,
        {
          images: recipeImage
        }
      ));
    }
    onSaved();

  }


  return (
    <div className="form-content">
      <input
        className="hidden-file"
        type="file"
        onChange={onFileSelected}
        ref={fileRef}
      />
      <div>
        <h1>Add photos </h1>
        <div className="spacer"/>
        <div className="photos-container">
          <PhotoItem photo={recipeImage} />
          <PhotoItem />
          <PhotoItem />
          <PhotoItem add onClick={() => selectFile()} />

        </div>
        <Button
          label="Next step"
          onClick={onSaveRecipe}
          anchored
        />
      </div>
    </div>
  );
}

export default AddPhotos;