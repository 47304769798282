import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { friendRemoved } from "redux/slices/friendsSlice";

// Here's an example of conditionally dispatching actions based on current state.
export const friendRemove = (friendshipId) =>
  async (dispatch, getState) => {
    try {
      const { token } = getState().user;
      await doRequest(`${API_URL}/friends/${friendshipId}`, "DELETE", {}, token);

      dispatch(friendRemoved(friendshipId));

    } catch (error) {
      console.error(error);
    }

  };