import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";


import { recipeAdd } from "redux/actions/recipes/recipeAdd";
import { recipeUpdate } from "redux/actions/recipes/recipeUpdate";
import Button from "components/buttons/Button";
import InputStepper from "components/input/InputStepper";
import { FaClock, FaUsers } from "react-icons/fa";
import { useState } from "react";


const AddTitle = ({ recipe, onSaved }) => {

  const dispatch = useDispatch();

  const [label, setLabel] = useState(recipe?.label || "");
  const [duration, setDuration] = useState(recipe?.duration || 25);
  const [people, setPeople] = useState(recipe?.people || 2);


  const onSaveRecipe = async () => {
    let recipeId = 0;
    if (!recipe) {
      recipeId = await dispatch(recipeAdd({ duration, people, label }));
    } else {
      recipeId = recipe.id;
      await dispatch(recipeUpdate(
        recipeId,
        { duration, people, label }
      ));
    }
    onSaved();

  }


  return (
    <div className="form-content">
      <div>
        <h1>This recipe is called</h1>
        <div className="spacer" />
        <div className="title-container">
          <input
            className="input-recipe-name"
            type="text"
            label="Name"
            defaultValue={recipe?.label}
            name="label"
            onChange={({ target }) => setLabel(target.value)}
          />
          <div className="container-about">
            <span className="sub">It's for</span>
            <InputStepper
              label="people"
              onChange={(amount) => { setPeople(amount) }}
              defaultValue={2}
              icon={<FaUsers size={16} />}
            />
          </div>
          <div className="container-about-2nd">

            <span className="sub">and takes </span>
            <InputStepper
              label="minutes"
              onChange={(amount) => { setDuration(amount) }}
              stepSize={5}
              defaultValue={25}
              icon={<FaClock size={14} />}

            />
          </div>
          <span className="sub"> to prepare </span>


        </div>
        <Button
          label="Next step"
          onClick={onSaveRecipe}
          anchored
        />
      </div>
    </div>
  );
}

export default AddTitle;