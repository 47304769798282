import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { recipesLoaded, tagsLoaded } from "redux/slices/recipesSlice";

export const recipesLoad = () =>
  async (dispatch, getState) => {

    const { token } = getState().user;
    const { data: recipes } = await doRequest(`${API_URL}/recipes?populate=images,tags`, "GET", null, token);

    const mappedRecipes = {};
    
    recipes.forEach(recipe => {
      mappedRecipes[recipe.id] = {
        ...recipe.attributes,
        id: recipe.id,
        images: recipe.attributes.images.data.map(({ attributes }) => {
          return attributes;
        }),
        tags: recipe.attributes.tags.data.map(({id, attributes }) => {
          return {...attributes, id};
        })
      };
    });

    const { data: tags } = await doRequest(`${API_URL}/tags`, "GET");

    const populatedTags = tags.map(({ id, attributes }) => {
      return { id, value: id, ...attributes };
    })

    dispatch(tagsLoaded(populatedTags));
    dispatch(recipesLoaded(mappedRecipes));
  };