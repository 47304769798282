import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";

import Landing from "pages/Landing";
import AppRoutes from "AppRoutes";

import "./App.scss";

function App() {


  return (
    <Router>
      <Route exact path="/" component={Landing} />
      <Route path="/app/*" component={AppRoutes} />
    </Router>
  );
}

export default App;
