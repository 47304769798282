import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Button from "components/buttons/Button";
import InputText from "components/input/InputText";
import { userLogin } from "redux/actions/user/userLogin";
import { snackbarShowError } from "redux/slices/snackbarSlice";

import Loader from "components/Loader";
import { userLoaded } from "redux/slices/userSlice";

import "./style.scss";

const Login = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  
  const { control, handleSubmit, formState: { errors } } = useForm();
  const { loading } = useSelector(state => state.user);

  const submitLogin = async (formValues) => {
    try {
      await dispatch(userLogin(formValues.email, formValues.password))
      history.push("/app/swipe");
    } catch (error) {
      dispatch(userLoaded());
      dispatch(snackbarShowError("Error trying to log in"));
    }
  }

  return (
    <div className="screen-login">
      {!loading ?
        <>
          <div className="container-logo">
            <div className="logo">
              <img
                className="logo-image"
                alt="Food swipe logo"
                src={require("assets/img/logo.svg").default}
              />
              <h1 className="logo-header">Dinner Deck</h1>
            </div>
          </div>
          <div className="container-form">
            <h2>Log in</h2>
            <div className="spacer" />
            <InputText
              label="Email address"
              name="email"
              control={control}
              error={errors.email}
              rules={{ required: true }}
              type="email"
            />
            <InputText
              label="Password"
              name="password"
              type="password"
              control={control}
              error={errors.password}
              rules={{ required: true }}
            />
            <div className="spacer" />
            <Button
              label="Log In" onClick={handleSubmit(submitLogin)} />
            <div className="spacer" />
            <Button
              label="Register"
              secondary
              onClick={() => history.push("/app/register")}
            />
          </div>
        </>
        : <Loader />
      }
    </div>
  );
}

export default Login;