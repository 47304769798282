import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { friendAccepted } from "redux/slices/friendsSlice";

export const friendAccept = (friendshipId) =>
  async (dispatch, getState) => {
    try {
      const { token } = getState().user;
      await doRequest(`${API_URL}/friends/${friendshipId}`, "PATCH", {
        accepted: true
      }, token);
      
      dispatch(friendAccepted(friendshipId));

    } catch (error) {
      console.error(error);
    }

  };