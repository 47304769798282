// We can also write thunks by hand, which may contain both sync and async logic.

import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { userLoggedIn, userLoading } from "redux/slices/userSlice";

export const userRegister = (username, email, password) =>
  async (dispatch) => {

    dispatch(userLoading());
    const { jwt, user } = await doRequest(`${API_URL}/auth/local/register`, "POST", {
      username,
      email,
      password,
    });
    
    dispatch(userLoggedIn({ jwt, user }));

  };