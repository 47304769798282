import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cards: {},
  filters: {
    tags: [],
    duration: undefined,
    people: undefined
  },
  loading: false
};

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    cardsLoading: (state) => {
      state.loading = true;
    },
    cardsLoaded: (state, { payload }) => {
      state.cards = payload;
      state.loading = false;
    },
    cardSwiped: (state, { payload: id }) => {
      state.cards[id].swiped = true;
    },
    cardRemoved: (state, { payload }) => {
      state.cards = payload;
    },
    cardsFilterUpdated: (state, { payload }) => {
      const { duration, people, tags } = payload;
      state.filters = {
        tags,
        duration,
        people
      }
    }
  }

});

export const {
  cardsLoading,
  cardsLoaded,
  cardSwiped,
  cardRemoved,
  cardsFilterUpdated
} = cardsSlice.actions;


export const selectCards = (state) => state.recipes.recipes;

//   // We can also write thunks by hand, which may contain both sync and async logic.
//   // Here's an example of conditionally dispatching actions based on current state.
//   export const incrementIfOdd = (amount) => (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default cardsSlice.reducer;