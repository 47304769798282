import React from "react";

import EmptyState from "components/EmptyState";

const Favorites = () => {
  return (
    <div className="page-favorites">
      <EmptyState
        icon={require("assets/icons/heart.svg").default}
        text="Favorites will be implemented soon!"
      />

    </div>
  );
}

export default Favorites;