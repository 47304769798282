import React, { Component } from 'react';

import "./style.scss";


class InfoItem extends Component {

  render() {
    return (
      <div className="info-item">
        <div className="container-circle-icon">
          {this.props.textIcon &&
            <div className="img-info">{this.props.textIcon}</div>
          }
        </div>
        <div className="block-info" >
          <h3>{this.props.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: this.props.text }} />
        </div>
      </div>
    );
  }
}
export default InfoItem;
