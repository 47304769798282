import { IconButton } from "@material-ui/core";
import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

import "./style.scss";

const InputStepper = ({
  onChange,
  defaultValue,
  stepSize = 1,
  label,
  icon
}) => {

  const [value, setValue] = useState(defaultValue);

  return (
    <div className="input-stepper">
      <div className="container-stepper">
        <div className="actions">
          <IconButton
            onClick={() => {
              setValue(value + stepSize);
              onChange(value);
            }}
          >
            <div className="stepper-button" >
              <FaPlus size={14} />
            </div>
          </IconButton>
          <div className="value">
            {value}
          </div>
          <IconButton
            disabled={value < 1}
            onClick={() => {
              setValue(value - stepSize);
              onChange(value);
            }}
          >
            <div className="stepper-button">
              <FaMinus size={14} />
            </div>
          </IconButton>
        </div>
        {label && <div className="label">{label}</div>}
      </div>
    </div>
  );
}

export default InputStepper;