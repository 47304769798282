import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recipes: {},
  tags: [],
  loading: false
};

export const recipesSlice = createSlice({
  name: "recipes",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    tagsLoaded: (state, { payload }) => {
      state.tags = payload;
    },
    recipeAdded: (state, { payload }) => {
      const id = new Date().getTime();
      state.recipes[id] = { id, ...payload };
    },
    recipeUpdated: (state, { payload }) => {
      const { id } = payload;
      state.recipes[id] = {...state.recipes[id],  ...payload};
    },
    recipesLoaded: (state, { payload }) => {
      state.recipes = payload;
    },
    recipeRemoved: (state, { payload: recipeId }) => {
      delete state.recipes[recipeId];
    }
  }
});

export const {
  recipeAdded,
  recipeUpdated,
  recipeRemoved,
  recipesLoaded,
  tagsLoaded
} = recipesSlice.actions;


export const selectRecipes = (state) => state.recipes.recipes;


export default recipesSlice.reducer;