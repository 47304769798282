import axios from "axios";
import { API_URL } from "constants";
import doRequest from "helpers/doRequest";

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

// Here's an example of conditionally dispatching actions based on current state.
export const userProfileImageUpdate = (newImage) =>
  async (dispatch, getState) => {

    const { token } = getState().user;

    var formData = new FormData();

    const imageFile = dataURLtoFile(newImage);
    formData.append("files", imageFile);

    const { data: images } = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      }
    })

    await doRequest(
      `${API_URL}/profile`, "PATCH",
      JSON.stringify({ profileImage: images[0].id }),
      token
    );

  };