import { FILES_URL } from "constants";
import React from "react";
import { FaClock, FaUsers } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import "./style.scss";

const Card = ({ recipe }) => {

  const history = useHistory();
  const onDragStart = () => {

  };

  return (
    <div
      className="swiper--card"
      onClick={() => history.push(`/app/swipe/${recipe.id}`)}
      id={recipe.id}
    >
      <span className="content">
        {recipe.images?.length > 0 &&
          <img
            src={`${FILES_URL}${recipe.images[0].url}`}
            className="image"
            alt="The recipe"
          />
        }
        <footer className="content-bottom">
          <span className="label">{recipe.label}</span>
          <div className="spacer" />
          <div className="cols">
            <div className="col stats">
              <img className="card-icon" src={require("assets/icons/clock-white.svg").default} />
              <span>{`${recipe.duration} minutes`}</span>
            </div>
            <div className="col stats">
              <img className="card-icon" src={require("assets/icons/friends-white.svg").default} />
              <span>{`${recipe.people} people`}</span>
            </div>
          </div>
        </footer>
      </span>
    </div>

  );
}

export default Card;