import React from "react";
import classNames from "classnames";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { FaClock, FaHeart, FaRegHeart, FaUsers } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import ButtonCloseModal from "components/buttons/ButtonCloseModal";
import ButtonIconEffect from "components/buttons/ButtonIconEffect";
import MarkdownView from "components/MarkdownView";
import { FILES_URL } from "constants";

import { snackbarShowMessage } from "redux/slices/snackbarSlice";
import { favoriteAdd } from "redux/actions/favorites/favoriteAdd";


import "./style.scss";

const RecipeOpen = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { cards } = useSelector(state => state.cards);
  const { favorites } = useSelector(state => state.favorites);
  const { recipeId } = useParams();
  const [closing, setClosing] = useState(false);
  const recipe = cards[recipeId];

  const favorite = !!favorites[recipeId];

  const onClose = () => {
    setClosing(true);
    setTimeout(() => {
      history.goBack();
    }, 400)
  }

  const onAddToFavorites = () => {
    dispatch(favoriteAdd(recipe));
    dispatch(snackbarShowMessage("Added recipe to favorites!"));
  }

  const recipeChosenClasses = classNames(
    "recipe-chosen",
    closing && "closing"
  );

  return (
    <div className={recipeChosenClasses}>
      {recipe.images?.length > 0 &&
        <img
          src={`${FILES_URL}${recipe.images[0].url}`}
          className="image"
          alt="The recipe"
        />
      }
      <div className="container-top">
        <ButtonCloseModal onClose={onClose} />
        <ButtonIconEffect
          onClick={onAddToFavorites}
          icon={favorite ? <FaHeart size={20} /> : <FaRegHeart size={20} />}
          className="button-favorite"
        />
        <h2>{recipe.label}</h2>
        <div className="spacer" />
        <div className="cols">
          <div className="col stats">
            <FaClock />
            <span>{`${recipe.duration} minutes`}</span>
          </div>
          <div className="col stats">
            <FaUsers />
            <span>{`${recipe.people} people`}</span>
          </div>
        </div>

      </div>
      <div className="container-content">
        <h3>Ingredients</h3>
        <MarkdownView text={recipe.ingredients} />
        <div className="spacer" />
        <h3>Preparation</h3>
        <MarkdownView text={recipe.description} />
      </div>
    </div>
  );
};

export default RecipeOpen;