import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { friendsLoaded } from "redux/slices/friendsSlice";

export const friendsLoad = () =>
  async (dispatch, getState) => {

    const { token } = getState().user;
    const friends = await doRequest(`${API_URL}/friends`, "GET", null, token);
    const mappedFriends = {};
    
    friends.forEach(friend => {
      mappedFriends[friend.id] = friend;
    });

    dispatch(friendsLoaded(mappedFriends));
  };