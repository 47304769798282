import Button from "components/buttons/Button";
import CardPreview from "components/CardPreview";
import HeaderPage from "components/HeaderPage";
import InfoItem from "components/InfoItem";
import React from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";


const demoRecipes = [
  {
    label: "Vegan delight",
    image: require("assets/img/vegan-delight.jpg"),
    duration: 30,
    people: 4,
  },
  {
    label: "Rich tortilla",
    image: require("assets/img/rich-tortilla.jpg"),
    duration: 35,
    people: 2,
  },
  {
    label: "Tasty cheese toasties",
    image: require("assets/img/tasty-cheese-toasties.jpg"),
    duration: 20,
    people: 2,
  },
]
const Landing = () => {

  const history = useHistory();

  return (
    <div className="page-landing">
      <HeaderPage />
      <div className="container-top">
        <div className="container-left">
          <div className="title-content">
            <h1 className="h1-landing">Always know what to eat!</h1>
            <p>Have trouble deciding what to have for dinner?
              With Dinner Deck you'll have all your own and your friends recipes
              in the palm of your hand. Simply swipe until you find something you like!
            </p>
            <div className="spacer" />
            <div className="spacer" />
            <div className="cols">
              <div className="col">
                <Button
                  white
                  label="Start swiping"
                  onClick={() => history.push("/app/login")}
                />
              </div>
              <div className="col">
                <Button
                  secondary
                  white
                  label="Register"
                  onClick={() => history.push("/app/register")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-right">
          <div className="card-previews">
            {demoRecipes.map((recipe, index) => {
              return (
                <CardPreview
                  recipe={recipe}
                  key={index}
                  index={index}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="container-middle">
        <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
          <defs>
            <linearGradient id="primary-gradient">
              <stop offset="0%" stop-color="#f34748" />
              <stop offset="100%" stop-color="#f35959" />
            </linearGradient>
          </defs>
          <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"></path>
        </svg>
      </div>
      <div className="container-bottom">
        <h2>Why Dinner Deck?</h2>
        <p>I never know what to make for dinner.
          Often I don't look back the past couple weeks and prepare the same few dishes over and over again.
          There's so much more delicious food to prepare, but it's somehow never top of mind.
        </p>
        <br /><br />
        <p>
          Dinner Deck encourages you to write down all your favorite dishes and then shuffles them like a deck of cards.
          Simply keep swiping until you find what's for dinner tonight!
        </p>
            <div className="spacer"/>
            <div className="spacer"/>
            <div className="spacer"/>
            <div className="spacer"/>
        <InfoItem
          title="Collect your recipes"
          text="Make a collection of all your favorite recipes and be sure to always find them back."
          textIcon="🍲"
        />
        <InfoItem
          title="Start adding friends"
          text="Add your friends to share your favorite recipes with one another."
          textIcon="👥"
        />
        <InfoItem
          title="Discover"
          text="Getting bored of your own or your friends recipes? Browse through the endless list of public recipes and be sure to find something new you like"
          textIcon="🌎"
        />
        <InfoItem
          title="Bookmark your favorites"
          text="Really like something you find? Add it to your favorites! This will let the creator know how much you appreciate their creativity plus you can now easily find it back."
          textIcon="💕"
        />
      </div>
    </div>
  );
}

export default Landing;