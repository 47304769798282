import axios from "axios";
import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { recipeUpdated } from "redux/slices/recipesSlice";

// Here's an example of conditionally dispatching actions based on current state.
export const recipeUpdate = (id, recipe) =>
  async (dispatch, getState) => {

    const { token } = getState().user;
    const {
      label,
      people,
      duration,
      ingredients,
      description,
      tags,
      image
    } = recipe;

    let imageIds = [];

    if (image && !image.startsWith("http")) {
      var formData = new FormData();
      formData.append("files", image);

      const { data } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        }
      })


      imageIds = data.map(image => {
        return image.id;
      });
    }

    await doRequest(
      `${API_URL}/recipes/${id}`,
      "PUT",
      JSON.stringify({
        data: {
          label,
          people,
          duration,
          description,
          ingredients,
          tags,
          images: imageIds.length > 0 ? imageIds : undefined
        }
      }),
      token
    );

    dispatch(recipeUpdated({ id, recipe }));

  };