import classNames from "classnames";
import React from 'react';

import "./style.scss";

const PhotoItem = ({ photo, add, onClick }) => {

  const photoItemClasses = classNames(
    "photo-item",
    !photo && "empty",
    add && "add"
  );

  return (
    <div onClick={add ? onClick : undefined} className={photoItemClasses}>
      {photo && <img className="photo-item-image" src={photo}/>}
    </div>
  );
}

export default PhotoItem;