import { API_URL } from "constants";
import doRequest from "helpers/doRequest";
import { recipeRemoved } from "redux/slices/recipesSlice";

export const recipeRemove = (recipeId) =>
  async (dispatch, getState) => {
    try {
      const { token } = getState().user;
      await doRequest(`${API_URL}/recipes/${recipeId}`, "DELETE", {}, token);

      dispatch(recipeRemoved(recipeId));

    } catch (error) {
      console.error(error);
    }
  };