import React from "react";

import "./style.scss";

const ButtonCloseModal = ({ onClose }) => {
  return (
    <svg
      className="button-close-modal"
      onClick={onClose}
      viewPort="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="1" y1="15"
        x2="15" y2="1"
        stroke="white"
        stroke-width="2" />
      <line x1="1" y1="1"
        x2="15" y2="15"
        stroke="white"
        stroke-width="2" />
    </svg>
  );
}

export default ButtonCloseModal;