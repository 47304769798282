import InputText from "../../components/input/InputText"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { cardsLoad } from "redux/actions/cards/cardsLoad";
import { cardsFilterUpdated } from "redux/slices/cardsSlice";

import InputMultiSelect from "components/input/InputMultiSelect";
import Button from "../buttons/Button";

import "./style.scss";
import { useState } from "react";
import InputSwitch from "components/input/InputSwitch";

const CardsFilter = ({ onClose }) => {

  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm();
  const { filters } = useSelector(state => state.cards)
  const { tags: allTags } = useSelector(state => state.recipes);
  const [people, setPeope] = useState(0);

  const onApplyFilters = (newFilters) => {
    dispatch(cardsFilterUpdated(newFilters))
    dispatch(cardsLoad())
    onClose();
  }

  const onResetFilters = () => {
    dispatch(cardsFilterUpdated({ tags: [], people: null, duration: null }))
    dispatch(cardsLoad())
    onClose();
  }

  return (
    <div className="cards-filter">
      <div className="container-form">
        <h3>Filters</h3>
        <div className="spacer" />

        <InputMultiSelect
          label="Tags"
          items={allTags}
          defaultValue={filters.tags || []}
          name="tags"
          control={control}
          error={errors.tags}
        />
        <InputSwitch
          control={control}
          name="toggle"
          error={errors.toggle}
          label="Include friend recipes"
        />
        <InputSwitch
          control={control}
          name="toggle"
          error={errors.toggle}
          label="Include public recipes"
        />
        <Button
          label="Apply filters"
          onClick={handleSubmit(onApplyFilters)}
        />
        <div className="spacer" />
        <Button
          label="Reset filters"
          secondary
          onClick={onResetFilters}
        />
      </div>
    </div>
  );
};

export default CardsFilter;